import {Background} from 'components/Background';
import Bold from 'components/Bold';
import {Button} from 'components/Button';
import {Card} from 'components/Card';
import CardsContainer from 'components/CardsContainer';
import {Clients} from 'components/Clients';
import {Content} from 'components/Content';
import {FaqsSection} from 'components/FaqsSection';
import {HubspotForm} from 'components/HubspotForm';
import {GradientBox} from 'components/landings/GradientBox';
import {List} from 'components/List';
import 'keen-slider/keen-slider.min.css';
import IndexBackground from 'components/monei-pay/IndexBackground';
import {PaymentMethods} from 'components/PaymentMethods';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {StyledImg} from 'components/StyledSx';
import {graphql} from 'gatsby';
import {Helmet, useI18next} from 'gatsby-plugin-react-i18next';
import cart2 from 'images/cart2.svg';
import check from 'images/check.svg';
import coins from 'images/coins.svg';
import cards from 'images/credit-cards.svg';
import glop from 'images/glop_logo.svg';
import holded from 'images/holded.svg';
import honei from 'images/honei_logo.png';
import integration from 'images/integration.svg';
import hotelgest from 'images/logo-hotelgest.png';
import nuveapp from 'images/logo-nuve-app.png';
import mobile_payment from 'images/mobile_payment.svg';
import modular from 'images/modular.svg';
import moneiLogo from 'images/monei_logo.svg';
import qamarero from 'images/qamarero_logo.png';
import security from 'images/security.svg';
import yumminnCaseStudy from 'images/yummin_case_study.png';
import yumminn from 'images/yumminn_logo_full.png';
import React, {useState} from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {docco} from 'react-syntax-highlighter/dist/esm/styles/hljs';

import styled from 'styled-components';
import {ScreenSizes} from '../../../types/responsive';

const CardWrapper = styled.div`
  max-width: 255px;
`;

const CardImage = styled.img`
  position: absolute;
`;
const CheckGreen = styled.img`
  margin: 4px 8px 0 0;
  svg {
    fill: white;
  }
`;
const IndexContent = styled.div`
  max-width: 33rem;
  @media (max-width: ${ScreenSizes.lg}) {
    max-width: 32rem;
  }
  @media (max-width: ${ScreenSizes.md}) {
    max-width: 100%;
    padding-top: 0.625rem;
  }
`;

export const IndexImage = styled.div`
  margin: 0px -70px 0 0;
  max-width: 500px;
  @media (max-width: ${ScreenSizes.lg}) {
    margin: 0;
  }

  @media (max-width: ${ScreenSizes.md}) {
    margin: 3.5rem 0 0 0;
    text-align: center;
    img {
      width: 65%;
    }
  }
  @media (max-width: ${ScreenSizes.xs}) {
    display: none;
  }
`;
export const partnersLogos = [
  {
    logo: yumminn,
    url: {
      es: 'https://yumminn.com/'
    },
    alt: 'Yumminn integration',
    width: 200,
    scale: 1
  },
  {
    logo: glop,
    url: {
      es: 'https://www.glop.es/'
    },
    alt: 'Glop integration',
    width: 90,
    scale: 1
  },
  {
    logo: honei,
    url: {
      es: 'https://honei.app/'
    },
    alt: 'Honei',
    width: 180,
    scale: 1
  },
  {
    logo: qamarero,
    url: {
      es: 'https://www.qamarero.com/'
    },
    alt: 'Qamarero',
    width: 200,
    scale: 1
  },
  {
    logo: holded,
    url: {
      es: 'https://holded.com/'
    },
    alt: 'Holded',
    width: 200,
    scale: 1
  },
  {
    logo: nuveapp,
    url: {
      es: 'https://thenuveapp.com/'
    },
    alt: 'Nuve App',
    width: 135,
    scale: 1
  },
  {
    logo: hotelgest,
    url: {
      es: 'https://hotelgest.com/'
    },
    alt: 'Hotelgest',
    width: 200,
    scale: 1
  }
];

const SectionDescription = styled.p`
  font-weight: 500;
  color: #fafafa;
  font-size: 16px;
  p {
    font-size: 1.25rem;
  }
  a {
    color: #ffffff;
    text-decoration: underline;
  }
`;

const CodeSnippet = styled(SyntaxHighlighter)`
  border-radius: 4px;
  padding: 1.5em !important;
  @media (max-width: ${ScreenSizes.md}) {
    margin-top: 2em;
  }
`;

const CtaImage = styled.img`
  position: absolute;
  bottom: -6px;
  right: 98px;
  width: 19.5%;

  @media (max-width: ${ScreenSizes.lg}) {
    bottom: 0;
    width: 240px;
    right: 60px;
  }
  @media (max-width: ${ScreenSizes.md}) {
    display: none;
  }
`;

const LargeSectionHeader = styled(SectionHeader)`
  h1 {
    font-size: 2.625rem;
    line-height: 3.25rem;
    @media (max-width: ${ScreenSizes.sm}) {
      font-size: 2rem;
      line-height: 2.75rem;
    }
  }
`;

const PaymentCard = styled.div`
  padding: 24px 48px;
  flex-basis: 100%;

  @media (max-width: 768px) {
    padding: 40px 32px;
  }
`;

type SnippetType = 'javascript' | 'php' | 'python';

const Connect: React.FC = () => {
  const {t} = useI18next();
  const [codeSnippet, setCodeSnippet] = useState<SnippetType>('javascript');

  const javascriptCodeSnippet = `const {Monei, PaymentTransactionType} = require('@monei-js/node-sdk');
const monei = new Monei('pk_test_36cf3e8a15eff3f5be983562ea6b13ec');
monei.payments.create({
  amount: 110, // 1.10 EUR
  currency: 'EUR',
  orderId: '14379133960355',
  paymentToken: '7cc38b08ff471ccd313ad62b23b9f362b107560b',
  callbackUrl: 'https://example.com/checkout/callback',
  completeUrl: 'https://example.com/checkout/complete',
  customer: {
    name: 'John Doe',
    email: 'example@example.com',
    phone: '+49123456789'
  }
});
`;
  const phpCodeSnippet = `<?php
$monei = new Monei\\MoneiClient('pk_test_36cf3e8a15eff3f5be983562ea6b13ec');
$monei->payments->create([
    'amount' => 110, // 1.10 EUR
    'orderId' => '14379133960355',
    'currency' => 'EUR',
    'payment_token' => '7cc38b08ff471ccd313ad62b23b9f362b107560b',
    'callback_url' => 'https://example.com/checkout/callback',
    'complete_url' => 'https://example.com/checkout/complete'
]);
`;
  const pythonCodeSnippet = `import Monei
from Monei import CreatePaymentRequest

monei = Monei.MoneiClient(api_key="pk_test_36cf3e8a15eff3f5be983562ea6b13ec")
monei.Payments.create(CreatePaymentRequest(
    amount=110,
    currency="EUR",
    order_id="14379133960355",
    payment_token="7cc38b08ff471ccd313ad62b23b9f362b107560b",
    callback_url="https://example.com/checkout/callback",
    complete_url="https://example.com/checkout/complete"
))
`;

  const snippets: Record<SnippetType, string> = {
    javascript: javascriptCodeSnippet,
    php: phpCodeSnippet,
    python: pythonCodeSnippet
  };

  const faqs = [
    {
      header: '¿Qué son los pagos integrados?',
      text: 'Las soluciones de pago integradas ayudan a agilizar y automatizar el proceso de aceptación de pagos. Es un sistema de pago que se comunica con el resto del software que utiliza tu empresa, lo que hace que la integración de pagos para cada plataforma o marketplace sea única.',
      content:
        'Las soluciones de pago integradas ayudan a agilizar y automatizar el proceso de aceptación de pagos. Es un sistema de pago que se comunica con el resto del software que utiliza tu empresa, lo que hace que la integración de pagos para cada plataforma o marketplace sea única.'
    },
    {
      header: t(
        '¿Cuáles son los principales beneficios de las soluciones de pago integradas para plataformas y marketplaces a través de MONEI Connect?'
      ),
      text: t(
        '- Ahorra tiempo y dinero desarrollando tu propia solución de pagos. - Obtén una participación en los ingresos. - Aprovecha el sistema de facturación de MONEI para cobrar a tus usuarios/comerciantes por servicios adicionales. - Evita lidiar con el cumplimiento de pagos. - Ayuda a tus usuarios a aceptar más métodos de pago. - Comete menos errores.'
      ),
      content: (
        <List sx={{marginTop: '12px'}}>
          <li>Ahorra tiempo y dinero desarrollando tu propia solución de pagos</li>
          <li>Obtén una participación en los ingresos</li>
          <li>
            Aprovecha el sistema de facturación de MONEI para cobrar a tus usuarios/comerciantes por
            servicios adicionales
          </li>
          <li>Ahorrate ocuparte del cumplimiento de pagos</li>
          <li>Ayuda a tus usuarios a aceptar más métodos de pago</li>
          <li>Comete menos errores</li>
        </List>
      )
    },
    {
      header: '¿Cuál es un ejemplo de pagos integrados?',
      text: 'Yumminn es una plataforma española que ayuda a los restaurantes a aceptar pedidos y pagos mediante códigos QR. Al utilizar MONEI Connect como socio de pagos integrado, Yumminn permite a sus usuarios aceptar todos los métodos de pago que admite MONEI sin tener que preocuparse por el desarrollo, el cumplimiento y el KYC. Todo está a cargo de MONEI. Otras plataformas que utilizan MONEI Connect para pagos integrados son Honei, Glop, Qamarero, Nuve y Taxitronic.',
      content:
        'Yumminn es una plataforma española que ayuda a los restaurantes a aceptar pedidos y pagos mediante códigos QR. Al utilizar MONEI Connect como socio de pagos integrado, Yumminn permite a sus usuarios aceptar todos los métodos de pago que admite MONEI sin tener que preocuparse por el desarrollo, el cumplimiento y el KYC. Todo está a cargo de MONEI. Otras plataformas que utilizan MONEI Connect para pagos integrados son Honei, Glop, Qamarero, Nuve y Taxitronic.'
    },
    {
      header:
        '¿Cuánto tiempo lleva integrar una solución de pago integrada en mi plataforma o mercado?',
      text: 'El tiempo necesario para la integración depende de varios factores, como la complejidad de la plataforma, la solución de pago elegida y la disponibilidad de los recursos para desarrolladores. En general, las API bien documentadas y las herramientas fáciles de usar proporcionadas por las soluciones de pago integradas (como MONEI Connect) pueden acelerar el proceso de integración.',
      content:
        'El tiempo necesario para la integración depende de varios factores, como la complejidad de la plataforma, la solución de pago elegida y la disponibilidad de los recursos para desarrolladores. En general, las API bien documentadas y las herramientas fáciles de usar proporcionadas por las soluciones de pago integradas (como MONEI Connect) pueden acelerar el proceso de integración.'
    },
    {
      header: '¿Hay algún requisito de cumplimiento o consideración de seguridad que deba conocer?',
      text: 'Absolutamente. Los requisitos de cumplimiento y las consideraciones de seguridad son cruciales a la hora de implementar una solución de pago integrada. Asegúrate de que la solución cumpla con los estándares pertinentes del sector, como el PCI-DSS (estándar de seguridad de datos de la industria de tarjetas de pago), para proteger los datos de pago confidenciales de los clientes.',
      content:
        'Absolutamente. Los requisitos de cumplimiento y las consideraciones de seguridad son cruciales a la hora de implementar una solución de pago integrada. Asegúrate de que la solución cumpla con los estándares pertinentes del sector, como el PCI-DSS (estándar de seguridad de datos de la industria de tarjetas de pago), para proteger los datos de pago confidenciales de los clientes.'
    },
    {
      header:
        '¿Qué tipo de atención al cliente o asistencia técnica se proporciona con las soluciones de pago integradas?',
      text: 'En MONEI, ofrecemos soporte al cliente y asistencia técnica para ayudarte durante el proceso de integración y resolver cualquier problema que pueda surgir. Esto puede incluir documentación, recursos para desarrolladores, canales de soporte dedicados y, a veces, incluso asistencia in situ, según el acuerdo de nivel de servicio (SLA) específico vigente. También nos encargamos de la seguridad y el cumplimiento de KYC, la incorporación y los pagos por ti.',
      content:
        'En MONEI, ofrecemos soporte al cliente y asistencia técnica para ayudarte durante el proceso de integración y resolver cualquier problema que pueda surgir. Esto puede incluir documentación, recursos para desarrolladores, canales de soporte dedicados y, a veces, incluso asistencia in situ, según el acuerdo de nivel de servicio (SLA) específico vigente. También nos encargamos de la seguridad y el cumplimiento de KYC, la incorporación y los pagos por ti.'
    }
  ];

  const schemaFaqs = {
    '@type': 'FAQPage',
    mainEntity: faqs.map((faq) => ({
      '@type': 'Question',
      name: faq.header,
      acceptedAnswer: {
        '@type': 'Answer',
        text: faq.text
      }
    }))
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <title>Soluciones de pago integradas para plataformas y marketplaces</title>
        <meta
          name="description"
          content="¿Buscas un socio de pagos integrado para tu plataforma o marketplace? Crea experiencias de pago sin los costes de desarrollo. Obtén MONEI Connect ››"
        />
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <IndexBackground>
        <Content>
          <Section
            centered
            sx={{
              marginTop: '1.875rem',
              flexDirection: {md: 'column'},
              gap: '90px'
            }}>
            <IndexContent>
              <StyledImg src={moneiLogo} sx={{width: '300px', marginBottom: '54px'}} />
              <LargeSectionHeader underline tagName="h1">
                La solución de pago integrada para tu plataforma o marketplace
              </LargeSectionHeader>
              MONEI Connect te permite integrar pagos de forma rápida y sencilla en tu plataforma o
              marketplace. Nuestra API te permite crear y escalar experiencias de pago de principio
              a fin. MONEI te permite ahorrar tiempo y dinero con la incorporación integrada, el KYC
              totalmente gestionado y el cumplimiento de normas de seguridad de los pagos.
            </IndexContent>
            <HubspotForm formId="1e6951bf-0cc8-4439-922b-f33a2ee988f5" minHeight={338} />
          </Section>
          <Clients clientList={partnersLogos} />
        </Content>
      </IndexBackground>
      <Content>
        <Section centered column sx={{textAlign: 'center'}}>
          <SectionHeader underline style={{textAlign: 'center'}}>
            Desarrolla tu negocio de pagos con rapidez y flexibilidad{' '}
          </SectionHeader>
          <p style={{textAlign: 'center'}}>
            Ya sea que tus usuarios vendan online o en persona, intégrate rápidamente con MONEI
            Connect para ayudarlos a aceptar más métodos de pago.
          </p>
          <CardsContainer sx={{marginTop: '100px'}}>
            <Card padding="80px 24px 32px 24px">
              <CardImage src={mobile_payment} alt="" style={{top: -45, width: 110}} />
              <CardWrapper>
                <p style={{marginBottom: '12px'}}>
                  <Bold>Incorporación de usuarios y liquidación simplificadas</Bold>
                </p>
                MONEI se encarga de los procesos de Conozca a su cliente (KYC) y de pago para que
                pueda incorporar más usuarios, más rápido y dedicar tiempo a las ventas en lugar de
                a las liquidaciones. Además dispones de nuestro equipo de Soporte durante todo el
                proceso de integración.
              </CardWrapper>
            </Card>
            <Card padding="80px 32px 32px 32px">
              <CardImage src={cards} alt="" style={{top: -40, width: 80}} />
              <CardWrapper>
                <p style={{marginBottom: '12px'}}>
                  <Bold>Solución flexible lista para usar</Bold>
                </p>
                Ahorra tiempo y dinero en el desarrollo integrándote con un único sistema de pago
                diseñado para escalar: añade pagos a tu plataforma o marketplace, o utiliza nuestra
                solución como marca blanca y cobra una comisión
              </CardWrapper>
            </Card>
            <Card padding="80px 32px 32px 32px">
              <CardImage src={security} alt="" style={{top: -48}} />
              <CardWrapper>
                <p style={{marginBottom: '12px'}}>
                  <Bold>Seguridad de pago integrada</Bold>
                </p>
                No te preocupes por el fraude y los problemas de seguridad de las transacciones,
                MONEI cumple con el estándar PCI DSS Level 1 nosotros nos encargaremos de la
                seguridad de los pagos por ti
              </CardWrapper>
            </Card>
          </CardsContainer>
          <SectionActions align="center" style={{marginBottom: 100}}>
            <Button variant="light" style={{marginRight: 15}} onClick={handleScrollToTop}>
              Contactar con ventas
            </Button>
          </SectionActions>
        </Section>
      </Content>
      <Background>
        <Content>
          <Section centered reverseOnMobile columnMd sx={{paddingTop: {sm: '100px'}}}>
            <div className="hide-on-mobile">
              <CodeSnippet language="javascript" style={docco}>
                {snippets[codeSnippet]}
              </CodeSnippet>
            </div>
            <div>
              <SectionHeader>
                Utiliza la API de integración de socios de pagos MONEI Connect
              </SectionHeader>
              <List className="flex-list" listStyle="none">
                <li>
                  <CheckGreen src={check} width={16} height={16} />︎ Accede a nuestras API en nombre
                  de cada cuenta de MONEI vinculada a tu cuenta de socio.
                </li>
                <li>
                  <CheckGreen src={check} width={16} height={16} />︎ Obtén un enlace de registro
                  único para que tus usuarios puedan registrar su cuenta de MONEI (siempre estarán
                  vinculados a tu cuenta de socio).
                </li>
                <li>
                  <CheckGreen src={check} width={16} height={16} />︎ Consulta el panel de control de
                  socios para ver el historial de pagos, los análisis agregados y los detalles de
                  cada cuenta de MONEI vinculada a tu cuenta de socio.
                </li>
              </List>

              <SectionActions align="left" style={{marginBottom: 100}}>
                <Button variant="dark" style={{marginRight: 15}} onClick={handleScrollToTop}>
                  Contactar con ventas
                </Button>
              </SectionActions>
            </div>
          </Section>
        </Content>
      </Background>
      <Content>
        <Section centered column sx={{textAlign: 'center'}}>
          <SectionHeader underline sx={{textAlign: 'center'}}>
            Ayuda a tus usuarios a hacer crecer sus negocios
          </SectionHeader>
          <CardsContainer sx={{margin: '40px 0'}}>
            <Card padding="80px 24px 32px 24px">
              <CardImage src={modular} alt="" style={{top: -45, width: 105}} />
              <CardWrapper>
                <p style={{marginBottom: '12px'}}>
                  <Bold>Acepta más métodos de pago</Bold>
                </p>
                Ayuda a los usuarios de tu plataforma o marketplace a aceptar la más amplia gama de
                métodos de pago, desde targetas de crédito hasta Apple Pay, Google Pay, Bizum, y más
                métodos de pago locales
              </CardWrapper>
            </Card>
            <Card padding="80px 32px 32px 32px">
              <CardImage src={coins} alt="" style={{top: -50, width: 80}} />
              <CardWrapper>
                <p style={{marginBottom: '12px'}}>
                  <Bold>Ahorra dinero</Bold>
                </p>
                A medida que tus usuarios vendan más, sus comisiones de transacción disminuirán en
                tiempo real y, para los pagos presenciales, MONEI Pay es un 50% más barato que un
                TPV tradicional
              </CardWrapper>
            </Card>
            <Card padding="80px 32px 32px 32px">
              <CardImage src={cart2} alt="" style={{top: -48, width: 110}} />
              <CardWrapper>
                <p style={{marginBottom: '12px'}}>
                  <Bold>Acepta pagos físicos y online</Bold>
                </p>
                Permite a los usuarios aceptar pagos online a través de una página de pago
                personalizable y en persona desde su teléfono o tableta, mediante códigos QR o
                solicitudes de pago
              </CardWrapper>
            </Card>
          </CardsContainer>
        </Section>
      </Content>
      <Content sx={{maxWidth: '1440px', textAlign: 'center'}}>
        <PaymentCard>
          <PaymentMethods />
        </PaymentCard>
      </Content>
      <Background sx={{marginTop: '100px', marginBottom: '190px'}}>
        <Content>
          <Section sx={{paddingBottom: '20px', paddingTop: {sm: '90px'}, alignItems: 'center'}}>
            <div>
              <SectionHeader>
                Yumminn aumentó los pagos sin contacto en un 17% con MONEI Connect
              </SectionHeader>
              La plataforma de hostelería de Yumminn es la forma más fácil y rápida para que los
              restaurantes, bares y hoteles de España acepten pedidos y pagos, pero sin incluir
              Bizum en su oferta de métodos de pago, era difícil satisfacer las necesidades de los
              usuarios de Yumminn y sus clientes finales. Todo esto cambió después de pasarse a
              MONEI Connect para sus pagos integrados.
            </div>
            <SectionImage
              src={yumminnCaseStudy}
              height={480}
              mobileWidth={400}
              style={{width: 'auto'}}
              className="hide-on-mobile"
            />
          </Section>
        </Content>
      </Background>
      <Content>
        <GradientBox wrapperStyle={{overflow: 'visible', marginTop: '60px'}}>
          <SectionHeader tagName="h3" underline style={{color: 'white'}}>
            Comienza hoy mismo con la mejor solución de pagos integrada para tu plataforma o
            marketplace
          </SectionHeader>
          <SectionDescription>
            Ponte en contacto con el departamento de ventas ahora para informarnos sobre tus
            necesidades comerciales y de pago.
          </SectionDescription>

          <SectionActions align="left">
            <Button variant="dark" style={{marginRight: 15}} onClick={handleScrollToTop}>
              Contactar con ventas
            </Button>
          </SectionActions>
          <CtaImage src={integration} />
        </GradientBox>
      </Content>
      <Content>
        <Section centered column>
          <FaqsSection
            faqs={faqs}
            title={'Preguntas frecuentes sobre soluciones de pago integradas'}
          />
        </Section>
      </Content>

      <Content>
        <Section textAlign="center">
          <div>
            <SectionHeader underline>¿Aún sigues aquí?</SectionHeader>
            <p>Prueba MONEI ahora</p>
            <SectionActions align="center" style={{marginBottom: 100}}>
              <Button variant="light" style={{marginRight: 15}} onClick={handleScrollToTop}>
                Contactar con ventas
              </Button>
            </SectionActions>
          </div>
        </Section>
      </Content>
    </>
  );
};

export default Connect;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "integrated-payments-partners"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
